<template>
    <div class="row">
    <div class="col-sm-12">
      <p class="text-center page-heading">Footwear order specifications</p>
    </div>
  </div>
  <div class="row my-12">
    <div class="col-6 ">
  <fieldset class="curve-box" >
  <legend class="subHeadingText">Foot arch</legend> 
    <div class="row my-2">
        <div class="col-6 ">
            <label>Left foot</label>
        </div>
        <div class="col-6 ">
            <select class="form-control" style="cursor: pointer;" @change="openResource($event)" v-model="openDisorder">
      <option value="1">High</option>
      <option value="2">Normal</option>
      <option value="3">Low</option>

      </select>
        </div>
        <div class="col-6 ">
            <label>Right foot</label>
        </div>
        <div class="col-6 ">
            <select class="form-control" style="cursor: pointer;" @change="openResource($event)" v-model="openDisorder">
      <option value="1">High</option>
      <option value="2">Normal</option>
      <option value="3">Low</option>
      </select>
        </div>
    </div>
    <!-- end row -->
    
   
   
    </fieldset>
</div>

<div class="col-6 ">
  <fieldset class="curve-box" >
  <legend class="subHeadingText">Joint mobility</legend> 
  <div class="row my-2">
        <div class="col-6 ">
            <label>Left foot</label>
        </div>
        <div class="col-6 ">
            <select class="form-control" style="cursor: pointer;" @change="openResource($event)" v-model="openDisorder">
      <option value="1">Normal</option>
      <option value="2">Hyper</option>
      <option value="3">Stiff</option>

      </select>
        </div>
        <div class="col-6 ">
            <label>Right foot</label>
        </div>
        <div class="col-6 ">
            <select class="form-control" style="cursor: pointer;" @change="openResource($event)" v-model="openDisorder">
                <option value="1">Normal</option>
      <option value="2">Hyper</option>
      <option value="3">Stiff</option>
      </select>
        </div>
    </div>
    <!-- end row -->
   
    </fieldset>
</div>
</div>


<div class="row my-12">
  <fieldset class="curve-box" >
  <legend class="subHeadingText">Left foot</legend> 
    <div class="row my-2">
        <img src="@/assets/images/leftfoot.png" class="img-fluid" alt="RxIx" />
    </div>
    <!-- end row -->
    
   
   
    </fieldset>

</div>

<div class="row my-12">
  <fieldset class="curve-box" >
  <legend class="subHeadingText">Right foot</legend> 
    <div class="row my-2">
        <img src="@/assets/images/rightfoot.png" class="img-fluid" alt="RxIx" />
    </div>
    <!-- end row -->
    
   
   
    </fieldset>

</div>

<div class="row my-12">
<div>
  <h5>Areas to offload</h5>
  <table>
    <thead>
      <tr>
        <th></th>
        <th>Left foot</th>
        <th>Right foot</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Hallux</td>
        <td>
            <input type="text"    autocomplete="off" class="form-control" />
        </td>
        <td>
            <input type="text"    autocomplete="off" class="form-control" />
        </td>
      </tr>
      <tr>
        <td>2nd-5th toes</td>
        <td>
            <input type="text"    autocomplete="off" class="form-control" />
        </td>
        <td>
            <input type="text"    autocomplete="off" class="form-control" />
        </td>
      </tr>
      <tr>
        <td>1st MPJ</td>
        <td>
            <input type="text"    autocomplete="off" class="form-control" />
        </td>
        <td>
            <input type="text"    autocomplete="off" class="form-control" />
        </td>
      </tr>
      <tr>
        <td>2nd MPJ</td>
        <td>
            <input type="text"    autocomplete="off" class="form-control" />
        </td>
        <td>
            <input type="text"    autocomplete="off" class="form-control" />
        </td>
      </tr>
      <tr>
        <td>3rd MPJ</td>
        <td>
            <input type="text"    autocomplete="off" class="form-control" />
        </td>
        <td>
            <input type="text"    autocomplete="off" class="form-control" />
        </td>
      </tr>
      <tr>
        <td>4th MPJ</td>
        <td>
            <input type="text"    autocomplete="off" class="form-control" />
        </td>
        <td>
            <input type="text"    autocomplete="off" class="form-control" />
        </td>
      </tr>
      <tr>
        <td>5th MPJ</td>
        <td>
            <input type="text"    autocomplete="off" class="form-control" />
        </td>
        <td>
            <input type="text"    autocomplete="off" class="form-control" />
        </td>
      </tr>
      <tr>
        <td>Lateral Midfoot</td>
        <td>
            <input type="text"    autocomplete="off" class="form-control" />
        </td>
        <td>
            <input type="text"    autocomplete="off" class="form-control" />
        </td>
      </tr>
      <tr>
        <td>Medial Midfoot</td>
        <td>
            <input type="text"    autocomplete="off" class="form-control" />
        </td>
        <td>
            <input type="text"    autocomplete="off" class="form-control" />
        </td>
      </tr>
      <tr>
        <td>Central Midfoot</td>
        <td>
            <input type="text"    autocomplete="off" class="form-control" />
        </td>
        <td>
            <input type="text"    autocomplete="off" class="form-control" />
        </td>
      </tr>
      <tr>
        <td>Complete Midfoot</td>
        <td>
            <input type="text"    autocomplete="off" class="form-control" />
        </td>
        <td>
            <input type="text"    autocomplete="off" class="form-control" />
        </td>
      </tr>
      <tr>
        <td>Complete Heel</td>
        <td>
            <input type="text"    autocomplete="off" class="form-control" />
        </td>
        <td>
            <input type="text"    autocomplete="off" class="form-control" />
        </td>
      </tr>
      <tr>
        <td>Posterior Heer</td>
        <td>
            <input type="text"    autocomplete="off" class="form-control" />
        </td>
        <td>
            <input type="text"    autocomplete="off" class="form-control" />
        </td>
      </tr>
      <tr>
        <td>None / Don't Know</td>
        <td>
            <input type="text"    autocomplete="off" class="form-control" />
        </td>
        <td>
            <input type="text"    autocomplete="off" class="form-control" />
        </td>
      </tr>
    </tbody>
  </table>
</div>
</div>



<div class="row my-12">
<div>
  <h5>Offloading methods</h5>
  <table>
    <thead>
      <tr>
        <th></th>
        <th>Left foot</th>
        <th>Right foot</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Scooping</td>
        <td>
            <input type="text"    autocomplete="off" class="form-control" />
        </td>
        <td>
            <input type="text"    autocomplete="off" class="form-control" />
        </td>
      </tr>
      <tr>
        <td>Reverse Scooping</td>
        <td>
            <input type="text"    autocomplete="off" class="form-control" />
        </td>
        <td>
            <input type="text"    autocomplete="off" class="form-control" />
        </td>
      </tr>
      <tr>
        <td>Redsitribution</td>
        <td>
            <input type="text"    autocomplete="off" class="form-control" />
        </td>
        <td>
            <input type="text"    autocomplete="off" class="form-control" />
        </td>
      </tr>
      <tr>
        <td>Rocker Outersole</td>
        <td>
            <input type="text"    autocomplete="off" class="form-control" />
        </td>
        <td>
            <input type="text"    autocomplete="off" class="form-control" />
        </td>
      </tr>
      <tr>
        <td>Wedge Outersole</td>
        <td>
            <input type="text"    autocomplete="off" class="form-control" />
        </td>
        <td>
            <input type="text"    autocomplete="off" class="form-control" />
        </td>
      </tr>
      <tr>
        <td>Other</td>
        <td>
            <input type="text"    autocomplete="off" class="form-control" />
        </td>
        <td>
            <input type="text"    autocomplete="off" class="form-control" />
        </td>
      </tr>
      <tr>
        <td>Let the FS Orthotist decide</td>
        <td>
            <input type="text"    autocomplete="off" class="form-control" />
        </td>
        <td>
            <input type="text"    autocomplete="off" class="form-control" />
        </td>
      </tr>
    </tbody>
  </table>
</div>
</div>

<div class="row my-12">
<div class="row mt-2">
                        <div class="col-sm-12">
                            <p class="mb-0">Patient's chief complaint<span class="star">*</span></p>
                        </div>
                    </div>
                    <div class="row my-3">
                        <div class="col-md-12 col-12">
                            <div id="CaseSheet" class="carousel slide" data-ride="carousel">
                                <div class="carousel-inner">
                                    <form class="caseSheetTextAreas">
                                        <textarea class="doctorWritenPlace" ></textarea>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



</template>
<script>
import axios from "axios";
export default {
props: ["docData"],
data() {
  return {
    newClinic:false,
    newClinician:false,
    email:'',
    firstName:'',
    mobile:'',
    lastName:'',
    isActive:false,
    coData: {},
    city:"",
    clinicConsult: [],
    clinicianData: [],
    hcpsDoctorsId: '',
    clinic_name: "",
    clinic_id: "",
    current_address:"",
    option_address:"",
    clinicName:"",
    coPincode: "",
    editbutton: false,
    currentlyEditedClinic:{},
    currentlyEditedPincodes: {},
    websiteValidationForm: false,
    payloadArray:[],
    nameRegex:/^[A-Za-z.\s]+$/,
    pincodeValidation:/^[1-9](\s*\d){5}$/,
    mobileValidation:/^[6-9]\d{9}$/,
    numberRegex:/^[1-9][0-9]*$/,
    clinicConsultationData:{},
    clinicUpdate:null
  };
},
computed: {
  
areFieldsClinicEmpty(){
  return (
    !this.nameRegex.test(this.clinic_name) ||
    !this.nameRegex.test(this.city)||
    !this.current_address||
    !this.pincodeValidation.test(this.coPincode)
  );
}
},
methods: {
  clearData(){
    this.clinic_name = '',
    this.current_address = '',
    this.option_address = '',
    this.city = ''
  },
  clearDataDoctor(){
    this.firstName = '',
    this.lastName = '',
    this.email = '',
    this.mobile = ''
  },
    enableClinic(){
       this.newClinic = true;
       this.clearData();
    },
    enableClinician(){
        this.newClinician = true
        this.clearDataDoctor();
        this.editbutton = false;

    },
  GoSetting() {
    window.scrollTo(0, 0);
    this.$router.push(`/clinicianothers/manage`);
  },

  handleDoctorEdit(value,index){
    this.updateClinicianData =value
    this.editbutton = true;
    console.log(value,"value  Doctor");
    
    this.newClinician=true
    this.email = value.email;
    this.firstName =value.firstName;
    this.mobile = value.mobile;
    this.lastName =value.lastName;


   
    // this.clinic_name = value.clinic_name;
    // this.current_address = value.current_address;
    // this.option_address = value.option_address;
    // this.city = value.city;
    // this.coPincode = value.pincode;

    let previousData = [...this.clinicianData]
      previousData[index].isEditable = true;
      this.clinicianData = previousData;
  },

  handleInputEditState(value,index){
    this.editbutton = true;
    console.log(value,"value");
    this.clinic_id = value.id
    console.log(this.clinic_id,"==>this.clinic_id")
    this.newClinic=true
    this.clinic_name = value.clinic_name;
    this.current_address = value.current_address;
    this.option_address = value.option_address;
    this.city = value.city;
    this.coPincode = value.pincode;
    this.clinicUpdate = value
    let previousData = [...this.clinicConsult]
      previousData[index].isEditable = true;
      this.clinicConsult = previousData;
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
    axios
      .get(`${BASE_API_URL}/hcps`)
      .then((response) => {
        console.log(response.data,"===response.data")
        console.log(this.clinic_id,"==>this.clinic_id")
        if(response.data) {
          const filteredDoctors = response.data.filter((doctors) => {
            return(
              this.clinic_id == doctors.clinic_hcp_id
            )
          })
          this.clinicianData = filteredDoctors
          console.log(filteredDoctors,"==>filteredDoctors")
        }
      })
  },
async createConsultation() {
  console.log("Enter")
    const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
        if (!this.clinic_name) {
            this.$swal("Please enter the clinic name!");
            return
        }
        if (!this.current_address) {
            this.$swal("Please enter the address!");
            return
        }
        if(!this.city){
            this.$swal("Please enter the city!");
            return
        }        
        if (!this.coPincode) {
            this.$swal("Please enter the pincode!");
            return
            }
        if(this.newClinician){

            if (!this.firstName) {
                this.$swal("Please enter the First Name!");
                return
            }
            if (!this.lastName) {
                this.$swal("Please enter the Last Name!");
                return
            }
            if (!this.mobile) {
                this.$swal("Please enter the mobile number!");
                return
            }
            if (!this.validMobile(this.mobile)) {
                return false;
            }
            if (!this.email) {
                this.$swal("Please enter the email ID!");
                return;
            }
            if (!this.validEmail(this.email)) {
                return false;
            }
        }
    // if(this.areFieldsClinicEmpty){ 
  let clinicPayload = {
        clinic_name: this.clinic_name,
        pincode: this.coPincode,
        city:this.city,
        current_address:this.current_address,
        option_address:this.option_address,
        // clinician_others_id: this.hcpsDoctorsId,
        admin_customer_id:this.coData?.customer?.id,
        role : 'hcp'
      };
      console.log(this.clinic_id,"==>this.clinic_id111")
      console.log(clinicPayload,"==>clinicPayload")
      if(this.clinic_id == ''){
      await axios
        .post(`${BASE_API_URL}/clinic-consultation`, clinicPayload)
        .then((response) => {
            if(response.data){
    const payload ={
      firstName : this.firstName,
      lastName : this.lastName,
      email : this.email,
      mobile : this.mobile,
      role:  'hcp',
      updated_by:'admin-customer',
      // clinic_id:response.data.id,
      clinic_id: response.data.id,
      pincode : this.coPincode,
      practiceId:[],
      doctorPatientId:[],
      Doctorconsultaion:[],
      consultation_id:[],
      }
      console.log(payload,"==>payload")
       axios
    .post(`${BASE_API_URL}/hcps`,payload)
    .then(async response =>{
        console.log(response.data);
    this.$swal("Record is created");
  this.getClinicConsultation() ;
    }
    )   }
          return response.data
        }
        )
        .catch((error) => {
          return error
          });
        }else{
          console.log("ELSE PART ENTER");
          console.log(this.clinic_id,"?????????????????????????????????????????????????");
          const payload ={
      firstName : this.firstName,
      lastName : this.lastName,
      email : this.email,
      mobile : this.mobile,
      role:  'hcp',
      updated_by:'admin-customer',
      // clinic_id:response.data.id,
      clinic_id: this.clinic_id ,
      pincode : this.coPincode,
      practiceId:[],
      doctorPatientId:[],
      Doctorconsultaion:[],
      consultation_id:[],
      }
      console.log(payload,"==>payload")
       axios
    .post(`${BASE_API_URL}/hcps`,payload)
    .then(async response =>{
        console.log(response.data);
    this.$swal("Record is created");
  this.getClinicConsultation() ;
    }
    ) 
        }
      // }
  },

  async updateConsultation() {
  console.log("JI");
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL

          let updateClinicPayload ={
            clinic_name: this.clinic_name,
            city:this.city,
            current_address:this.current_address,
            option_address:this.option_address,
            pincode: this.coPincode,
          }
          await  axios
          .put(`${BASE_API_URL}/clinic-consultation/${this.clinicUpdate.id}/update`, updateClinicPayload)
          .then(async (response)=>{
            if(response.data){
              let reupdatePayload = {
                firstName: this.firstName,
                lastName: this.lastName,
                email: this.email,
                mobile: this.mobile,
                clinic_id: this.clinicUpdate.id,
            };
            console.log(this.updateClinicianData.id,"==>this.updateClinicianData.id")
            console.log(reupdatePayload,"==>reupdatePayload")
            await axios
                .put(`${BASE_API_URL}/hcps/${this.updateClinicianData.id}/update`, reupdatePayload, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    if (response?.data) {
                      this.$swal(
                "Updated Successfully!!!"
              );
                    }
                })
            }
          })

                .catch(function (error) {
                    console.log(error?.response);
                });
        },

  validMobile(inputtxt){
            const mobilevalidation = /^[6-9]\d{9}$/;
            if(inputtxt.match(mobilevalidation)){
                return true
            }else{
                this.$swal("Please enter the correct mobile number!");
                return false;
            }
        },
    validEmail(inputtxt) {
        var email = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        var consecutiveSpecialChar = /[\W]{2}/;

        if (inputtxt.match(email) && !consecutiveSpecialChar.test(inputtxt)) {
            return true;
        } else {
            this.$swal("Please enter the correct email ID!");
            return false;
        }
    },
  getClinicConsultation() {
    const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
    axios
      .get(`${BASE_API_URL}/clinic-consultation`)
      .then((response) => {
        console.log(this.coData?.customer?.id,"===>coooo0000000")
        console.log(response.data,"==>response.data.........")
        const filteredValue = response.data.filter((consult) => {
          return(
            this.coData?.customer?.id === consult?.admin_customer_id?.id
          )
        })
        console.log(filteredValue,"===>filteredValue>>>>>>")    
        this.clinicConsult = filteredValue;
          const createdData = this.clinicConsult.filter(item => {
              return item.clinician_others_id?.id === this.hcpsDoctorsId && item.role == 'clinician-others'
              });
            this.clinicConsultationData =createdData.length > 0 ? createdData[0] : null;
          this.clinic_name = this.clinicConsultationData.clinic_name,
          this.city = this.clinicConsultationData.city,
          this.coPincode = this.clinicConsultationData.pincode,
          this.current_address =this.clinicConsultationData.current_address
})
  },
  async getClinicianOthersData() {
    const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
    await axios
    .get(`${BASE_API_URL}/hcps/${this.hcpsDoctorsId}/one`)

      .then((response) => {
        this.coData = response.data
        this.coPincode = this.coData.pincode
      })
  },
  async getDocters(){
    const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
    axios
      .get(`${BASE_API_URL}/hcps`)
      .then((response) => {
        console.log(response.data,"RESPONSE");
      })
  },
},
created: async function () {
  let hcpsDoctorID = localStorage.getItem('id');
   this.hcpsDoctorsId = JSON.parse(hcpsDoctorID);
   await this.getClinicianOthersData();
 await this.getClinicConsultation();
 await this.getDocters();
},
};
</script>
<style>

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: left;
}

th {
  background-color: #f4f4f4;
}

.clinicSubmitBtn{
    display: flex;
    justify-content:center;
}
 fieldset.curve-box {
    border: 2px solid #00979e;
    border-radius: 20px;
    padding: 15px;
    margin-top: 1.5rem;
    }
legend.subHeadingText {
padding: 4px 10px;
margin-bottom: 0px;
display: block;
float: none;
width: auto;
font-size: 20px;
}
.allfieldcontent{
color: red;
margin-top: 15px
}
.star {
  color: red;
  font-size: 20px;
}
.hideOpacity{
opacity: 0;
}
.yes-button,
.no-button {
padding: 10px 20px;
border: none;
border-radius: 4px;
cursor: pointer;
}

.yes-button {
background-color: #38761D;
color: #fff;
}

.no-button {
background-color: #FF9900;
color: #fff;
}
.popup {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(0, 0, 0, 0.2) !important;
display: flex;
align-items: center;
justify-content: center;
}

.popup-content {
text-align: center;
background: #fff;
width: 400px;
padding: 20px;
border-radius: 8px;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
.cross-icon{
font-size:25px;
color: #cd381c;
cursor: pointer;
float: right;
}
.clinicCreateBtn{
color: #00979e !important;
cursor: pointer;
}

button#btn_clinicconsult_add {
  border-color: transparent;
}

.disableCreateBtn{
color:  #dddddd !important;
pointer-events: none;
cursor: auto; 
}
.clinicTextAlign input{
text-align: left !important;;
}

/* .receiveConsultation {
color: #34989f;
}

.checkbox-inline {
color: #34989f;
} */
.checkbox-inline.heading {
font-size: 20px;
margin-bottom: 0px;
}

.my-3.subHeading {
/* color: #34989f; */
font-size: 18px;
}

.ms-5.text-color {
/* color: #34989f; */
font-size: 18px;
}
@media screen and (max-width:991px) {
.mobileStyleDoctor {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 6rem;
}
.mypracticeMobile {
  margin-left: 20px;
  /* margin-bottom: 10px; */
  margin-top: 1rem;
  color: #000;
  font-weight: 550;
  font-size: 21px;
}
}
</style>
